var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('section',{staticClass:"section bg-light"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"features-absolute rounded bg-white shadow"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 col-md-12 my-4"},[_c('div',{staticClass:"row"},[_vm._l((_vm.props.cards),function(card,idx){return _c('div',{key:idx,staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"media features p-4"},[_c('div',{staticClass:"icon text-center rounded-circle text-primary mr-3 mt-2"},[_c('i',{class:card.icon})]),_c('div',{staticClass:"media-body"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(card.title))]),_c('p',{staticClass:"text-muted para mb-0"},[_vm._v(" "+_vm._s(card.description)+" ")]),_c('div',{staticClass:"mt-2"},[(card.btnTxt)?_c('a',{staticClass:"text-primary",attrs:{"href":""}},[_vm._v(_vm._s(card.btnTxt)+" "),_c('i',{staticClass:"mdi mdi-chevron-right mr-1"})]):_vm._e()])])])])}),_vm._m(1)],2)])])])])])])]),_c('section',[_c('div',{staticClass:"container mt-3 mb-100"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(2),_c('div',{staticClass:"col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0"},[_c('div',{staticClass:"section-title ml-lg-5"},[_vm._m(3),_vm._m(4),_c('p',{staticClass:"text-muted para-desc ml-4 pl-2"},[_vm._v(" "+_vm._s(_vm.props.tab2[0].description)+" "),_c('i',{staticClass:"mdi mdi-chevron-right mr-1 text-primary"}),_vm._v(" "),_c('a',{staticClass:"text-primary font-weight-light",attrs:{"href":""}},[_vm._v("Ir al formulario")])]),_vm._m(5),_c('p',{staticClass:"text-muted para-desc ml-4 pl-2"},[_vm._v(" "+_vm._s(_vm.props.tab3[0].description)+" "),_c('i',{staticClass:"mdi mdi-chevron-right mr-1 text-primary"}),_vm._v(" "),_c('a',{staticClass:"text-primary font-weight-light",attrs:{"href":""}},[_vm._v("Ir al formulario")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-half-260 sect"},[_c('div',{staticClass:"bg-overlay",staticStyle:{"opacity":"0.45"}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"title-heading"},[_c('h4',{staticClass:"display-4 mb-4 font-weight-bold text-white title-dark"},[_vm._v(" Puedes realizar "),_c('br'),_vm._v(" tus "),_c('span',{staticClass:"text-primary"},[_vm._v("Solicitudes")]),_vm._v(" aquí ")]),_c('p',{staticClass:"para-desc text-white-50"},[_vm._v(" Con nuestro sistema de accesos directos a Solicitudes té                 ofrecemos una manera más rápida de realizar tus peticiones                 guiándote a través de nuestra web hacia los formularios de                 destino ")]),_c('div',{staticClass:"mt-4 pt-2"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":"javascript:void(0)"}},[_vm._v("Contactar !")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 d-none d-lg-block position-absolute dogI"},[_c('img',{staticClass:"img-fluid my-auto pet-img",attrs:{"src":require("@/assets/construction/portada.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-5 col-md-6"},[_c('img',{staticClass:"img-fluid rounded",attrs:{"src":require("@/assets/construction/beagle.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"title mb-4"},[_c('span',{staticClass:"font-weight-light"},[_vm._v("Te ofrecemos estos ")]),_c('span',{staticClass:"text-primary font-weight-normal"},[_vm._v("Servicios ")]),_c('span',{staticClass:"font-weight-light"},[_vm._v("adicionales")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"text-primary font-weight-normal"},[_c('i',{staticClass:"uil uil-arrow-circle-right text-primary mr-1"}),_vm._v(" Aplica como Intermediario ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"text-primary font-weight-normal"},[_c('i',{staticClass:"uil uil-arrow-circle-right text-primary mr-1"}),_vm._v(" Aplicar como proveedor ")])
}]

export { render, staticRenderFns }